<template>
  <div
    class="search"
    :class="{ 'search--active': searchDialogOpen }"
  >
    
<FormKitLazyProvider>
<div
      class="backdrop"
      :class="{ 'backdrop--active': searchDialogOpen }"
      @click="closeDialog()"
    />

    <div class="search__panel">
      <FormKit
        id="search__form"
        name="search__form"
        form-class="search__form"
        type="form"
        :incomplete-message="$t('Sorry, not all fields are filled out correctly.')"
        :actions="false"
        @submit="doSearch"
      >
        <input
          ref="searchInput"
          v-model="search"
          type="search"
          class="formkit-input"
          data-testid="quicksearch"
          :placeholder="searchPlaceholder"
          @focus="searchDialogOpen = true"
        >

        <button
          type="submit"
          class="search__submit"
        >
          <IconSearch class="icon icon--search" />
        </button>

        <button
          v-if="search.length > 0"
          type="button"
          class="search__reset"
          @click="search = ''"
        >
          <IconClose class="icon icon--close" />
        </button>

        <span
          v-if="search.length > 0"
          class="search__divider"
        />

        <button
          type="button"
          class="search__close"
          @click="closeDialog()"
        >
          <IconCssArrow2 class="icon icon--arrow icon--arrow-left" />
        </button>
      </FormKit>

      <div class="search__results">
        <div v-if="search.length > 3 && !hasResults && !loading">
          {{ $t('Unfortunately we were not able to find any results for your search') }}
        </div>
        <div v-else-if="hasResults">
          <div
            v-if="categories.length > 0"
            class="search__section"
          >
            <ul class="search__list">
              <li
                v-for="category in categories"
                :key="category"
                class="search__item"
              >
                <AppLink
                  :to="`/${category.url}/`"
                  class="search__link"
                  @click="closeDialog"
                >
                  <span>
                    <span class="search__label">
                      <UiRichtext :html="highlight(category.label)" />
                    </span>

                    <span
                      v-if="category?.parents?.[0]?.label"
                      class="search__parent"
                    >
                      in {{ category.parents[0].label }}
                    </span>
                  </span>

                  <IconCssArrow2 class="icon icon--arrow" />
                </AppLink>
              </li>
            </ul>
            <!-- <pre>categories: {{ categories }}</pre> -->
          </div>

          <div
            v-if="products.length > 0"
            class="search__section"
          >
            <ul class="search__products">
              <li
                v-for="product in products"
                :key="product"
                class="search__item"
              >
                <AppLink
                  :to="product.url"
                  class="search-product"
                  @click="closeDialog"
                >
                  <img
                    :src="getProductImage(product.image, 'f=rs:fit:100:100', product.name)"
                    :alt="product.name"
                    class="search-product__image"
                    width="46"
                    height="46"
                    loading="lazy"
                  >
                  <span>{{ product.name }}</span>
                </AppLink>
              </li>
            </ul>

            <!-- <pre>totalProducts: {{ totalProducts }}</pre> -->
            <!-- <pre>products: {{ products }}</pre> -->
          </div>

          <div
            v-if="pages.length > 0"
            class="search__section"
          >
            <div class="search__heading heading heading--6">
              {{ $t('Pages') }}
            </div>

            <ul class="search__list">
              <li
                v-for="page in pages"
                :key="page"
                class="search__item"
              >
                <AppLink
                  :to="`/${page.url}/`"
                  class="search__link"
                  @click="closeDialog"
                >
                  <span>
                    {{ page.label }}
                    <!-- <span class="search__label">
                      <UiRichtext :html="highlight(page.label)" />
                    </span> -->

                    <!-- <span
                      v-if="page?.parents?.[0]?.label"
                      class="search__parent"
                    >
                      in {{ page.parents[0].label }}
                    </span> -->
                  </span>

                  <IconCssArrow2 class="icon icon--arrow" />
                </AppLink>
              </li>
            </ul>

            <!-- <pre>pages: {{ pages }}</pre> -->
          </div>
        </div>

        <div v-else-if="recentSearches.length > 0">
          <div class="search__heading heading heading--6">
            {{ $t('Recent search terms') }}
          </div>

          <ul class="search__list">
            <li
              v-for="recentSearch in recentSearches"
              :key="recentSearch"
              class="search__item"
            >
              <button
                type="button"
                class="search__link"
                @click="setSearch(recentSearch)"
              >
                <span>{{ recentSearch }}</span>
                <IconCssArrow2 class="icon icon--arrow" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
</FormKitLazyProvider>

  </div>
</template>

<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import type { DataLayerItem } from '~/composables/useDataLayer'
import layoutQuery from '~layers/app/graphql/layout.gql'

const {
  data,
  search,
  searchDialogOpen,
  recentSearches,

  loading,

  hasResults,
  categories,
  pages,
  products,
  // totalProducts,

  searchRedirect,
} = useQuickSearch()

const { breakpoint } = useApp()

const { public: config } = useRuntimeConfig()
const { data: layoutData } = await useAsyncQuery(layoutQuery, { locale: config.strapiLocale })

const { viewItemList } = useDataLayer()
const dataLayerOverrides = computed<Partial<DataLayerItem>>(() => ({ item_list_name: 'quick-search', item_list_id: search.value }))

const searchInput = useTemplateRef('searchInput')

const searchPlaceholders = ref<Record<string, string>>({
  desktop: layoutData.value?.copies?.find((item: never) => item.UID === 'search__desktop')?.Richtexts?.[0]?.Richtext?.[0]?.children?.[0]?.text || '',
  mobile: layoutData.value?.copies?.find((item: never) => item.UID === 'search__mobile')?.Richtexts?.[0]?.Richtext?.[0]?.children?.[0]?.text || '',
})

const searchPlaceholder = ref<string>(searchPlaceholders.value.desktop)

watch(loading, () => {
  if (!loading.value) {
    // searchDialogOpen.value = true
    if (products.value.length > 0) {
      viewItemList(products.value, dataLayerOverrides.value)
    }
  }
})

watch(search, () => {
  if (!search.value) {
    // searchDialogOpen.value = false
    data.value = null
  }
})

const setSearch = (newSearch: string) => {
  search.value = newSearch
}

const closeDialog = () => {
  searchDialogOpen.value = false
  search.value = ''
}

const onKeyPress = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    closeDialog()
  }
}

const doSearch = () => {
  setTimeout(() => {
    searchRedirect()
    closeDialog()
    searchInput.value.blur()
  }, 600)
}

const highlight = (label: string) => {
  return label.replace(search.value, `<mark class="search__highlight">${search.value}</mark>`)
}

onMounted(() => {
  window.addEventListener('keyup', onKeyPress)

  watchEffect(() => {
    if (['mobile', 'phablet', 'tablet'].includes(breakpoint.value)) {
      searchPlaceholder.value = searchPlaceholders.value?.mobile
    }
    else {
      searchPlaceholder.value = searchPlaceholders.value?.desktop
    }
  })
})

onUnmounted(() => {
  window.removeEventListener('keyup', onKeyPress)
})
</script>

<!-- <style src="~layers/app/components/App/QuickSearch.css" /> -->
<style>
@import url("~layers/app/components/App/QuickSearch.css");
</style>
