<template>
  <div class="mini-account">
    <AppLink
      :to="{ name: 'account' }"
      class="mini-account__link"
      :aria-label="$t('My account')"
    >
      <IconCustomer class="mini-account__icon icon icon--customer" />
      <span class="mini-account__label">
        {{ $t('My account') }}
      </span>
    </AppLink>
  </div>
</template>

<!-- <style src="~layers/app/components/Account/MiniAccount.css" /> -->
<style>
@import url("~layers/app/components/Account/MiniAccount.css");
</style>
