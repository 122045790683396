<template>
  <ul class="country-switch">
    <li
      v-for="hrefLang in hrefLangs"
      :key="hrefLang.key"
      :class="{ 'country-switch__selected': hrefLang.selected }"
    >
      <a
        :href="hrefLang.value"
        @click="onClick($event, hrefLang)"
      >
        <span
          class="country-switch__label"
        >
          {{ hrefLang.label }}
        </span>
      </a>
    </li>
  </ul>
</template>

<script setup lang="ts">
const { public: config } = useRuntimeConfig()

const { fetchRoute, routeData } = useCustomRouter()
const { locale, setLocale } = useI18n({ useScope: 'global' })

const route = useRoute()
await fetchRoute(route.path)

const hrefLangs = computed(() => {
  return config?.i18n?.locales?.filter((item: any) => item.storeCode)?.map((item: any) => {
    const path = routeData.value?.global?.find((globalRoute: any) => globalRoute.storeCode === item.storeCode)?.url?.url || ''

    return {
      selected: item?.code === locale.value,
      key: item?.code,
      label: item?.name,
      flag: item?.flag,
      value: item?.domain + useSanitizeUrl('/' + path),
    }
  }) || []
})

const onClick = (e, hrefLang) => {
  if (config.isDev) {
    setLocale(hrefLang.key)
    e.preventDefault()
  }
}
</script>

<!-- <style src="~layers/app/components/App/CountrySwitch.css" /> -->
<style>
@import url("~layers/app/components/App/CountrySwitch.css");
</style>
