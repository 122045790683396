export function useNavigation() {
  const open = useState('navigationOpen', () => false)
  const showSubmenu = useState<number | null>('showSubmenu', () => null)

  let delay = null
  const { breakpoint } = useApp()

  const hasDrawer = computed(() => {
    return ['mobile', 'phablet', 'tablet', 'desktop'].includes(breakpoint.value)
  })

  function openNavigation() {
    open.value = true
  }

  function closeNavigation() {
    open.value = false
    showSubmenu.value = null
  }

  function toggleNavigation() {
    if (open.value) {
      closeNavigation()
    }
    else {
      openNavigation()
    }
  }

  function hasSubitems(item: any) {
    return (item?.Column1?.length || item?.Column2?.length || item?.Column3?.length) && item?.ShowSubNavigation
  }

  function mouseOver(item: any, index: number) {
    if (hasSubitems(item) && !hasDrawer.value) {
      if (delay) {
        clearTimeout(delay)
        delay = null
      }

      delay = setTimeout(() => {
        showSubmenu.value = index
      }, 200)
    }
  }

  function mouseOut() {
    if (!hasDrawer.value) {
      if (delay) {
        clearTimeout(delay)
        delay = null
      }

      showSubmenu.value = null
    }
  }

  function keypress(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      closeNavigation()
    }
  }

  function toggleSubnavigation(index, item) {
    if (hasDrawer.value && item?.ShowSubNavigation) {
      if (showSubmenu.value === index) {
        showSubmenu.value = null
      }
      else {
        showSubmenu.value = index
      }
    }
    else {
      navigateTo(item.Link)

      if (hasDrawer.value) {
        closeNavigation()
      }
    }
  }

  return {
    open,
    showSubmenu,
    hasDrawer,
    openNavigation,
    closeNavigation,
    toggleNavigation,
    hasSubitems,
    mouseOver,
    mouseOut,
    keypress,
    toggleSubnavigation,
  }
}
