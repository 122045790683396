<template>
  <div>
    <div class="added-to-cart__product-visual">
      <AppLink :to="item.product.product.url">
        <img
          :src="image"
          :alt="item.product.name"
        >
      </AppLink>
    </div>
    <div class="added-to-cart__product-info">
      <div class="product-info">
        <div class="product-info__content">
          <div class="product-info__brand">
            {{ productInfo.brand }}
          </div>
          <b class="product-info__name">
            {{ productInfo.name }}
          </b>
        </div>

        <ProductPrice
          v-if="productPrices"
          :prices="productPrices"
          :show-currency="false"
          :show-vat-tag="false"
          class="product-info__price"
        />
      </div>
      <ul
        v-if="item?.configurable_options"
        class="added-to-cart__product-size"
      >
        <li
          v-for="(option, index) in item.configurable_options"
          :key="index"
        >
          <span v-if="item?.context?.value && item?.context?.tab !== 'eu'">
            {{ item?.context?.value }} (EU: {{ option?.value_label }})
          </span>
          <span v-else-if="item?.context?.local_size && item?.context?.size_chart_code !== 'eu' ">
            {{ item?.context?.local_size }} (EU: {{ option?.value_label }})
          </span>
          <span v-else>
            {{ option?.value_label }}
          </span>
        </li>
      </ul>
    </div>
    <slot />
  </div>
</template>

<script lang="ts" setup>
import type { MagentoCartItem } from '~layers/app/types/magento'

export interface Props {
  item: MagentoCartItem
}

const { public: config } = useRuntimeConfig()
const props = defineProps<Props>()

const productInfo = computed<{ name: string, descriptive: string }>(() => {
  return {
    brand: props.item?.product?.product?.brand?.label || '',
    name: props.item?.product?.product?.name || '',
    descriptive: props.item?.product?.product?.descriptiveName || '',
  }
})

const productPrices = computed<{ regularPrice: number, finalPrice: number }>(() => {
  return {
    regularPrice: props.item?.product?.price_range?.minimum_price?.regular_price?.value,
    finalPrice: props.item?.product?.price_range?.minimum_price?.final_price?.value,
  }
})

const image = computed<string>(() => {
  if (props.item.configured_variant?.image?.url && !props.item.configured_variant?.image?.url.includes('placeholder')) {
    return getProductImage(props.item.configured_variant.image.url, 'f=rs:fit:400:400')
  }
  if (props.item.product.image?.url && !props.item.product.image?.url.includes('placeholder')) {
    return getProductImage(props.item.product.image.url, 'f=rs:fit:400:400')
  }

  return `${config.xcdnUrl}media/catalog/product/placeholder/default/Product.FallBack.jpg`
})
</script>
